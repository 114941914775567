.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-items: center;
    align-items: center;
    gap: 10px;
    padding-bottom: 50px;
}
  
.grid-item {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}
  
.video {
    width: 100%;
    height: 100%;
    max-width: 350px;
    height: auto;
    cursor: pointer;
}
  
.video img {
    width: 100%;
    height: auto;
}

.results {
    padding: 50px 10px 50px 10px;
    color: white;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    font-family: "Space Grotesk", sans-serif;
    font-size: 1.5rem;
    text-align: center;
}

.ver-mas-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #FCD42F;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media screen and (min-width: 1550px) {
    .results {
        font-size: 2rem;
    }
  }
  
  @media (min-width: 768px) {
    .grid-container {
      grid-template-columns: repeat(4, 1fr);
    }
  }