
.container-icon1,.container-icon3 {
  background-color: #ecebeb;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  padding-bottom: 80px;
}
.container-icon1{
  padding-top: 80px;

}
.txt-icon1,.txt-icon2,.txt-icon3 {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 2rem;
  margin-left: 80px;
  margin-right: 80px;
}

.img-icon1,.img-icon2,.img-icon3 {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  width: 40%;
  padding-bottom: 20px;
}

.container-icon2 {
  background-color: #ecebeb;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  padding-bottom: 80px;
}

@media screen and (min-width: 1px) and (max-width: 1300px) {
  .container-icon1,.container-icon3 {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .txt-icon1,.txt-icon2,.txt-icon3 {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 1rem;
  }

  .img-icon1,.img-icon2,.img-icon3 {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    width: 80%;
  }
  .container-icon2 {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

}
@media screen and (min-width: 1px) and (max-width: 500px) {
  .container-icon1,.container-icon3 {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .txt-icon1,.txt-icon2,.txt-icon3 {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    margin-left: 30px;
    margin-right: 30px;
  }

  .img-icon1,.img-icon2,.img-icon3 {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    width: 100%;
  }
  .container-icon2 {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
}

@media screen and (min-width: 501px) and (max-width: 600px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 700px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 701px) and (max-width: 800px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 801px) and (max-width: 900px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 901px) and (max-width: 1000px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 1.7rem;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1100px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1101px) and (max-width: 1200px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 1.9rem;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1301px) and (max-width: 1400px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 1.8rem;
  }
  .img-icon1,.img-icon2,.img-icon3 {
    width: 70%;
  }
}

@media screen and (min-width: 1401px) and (max-width: 1500px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 1.8rem;
  }
  .img-icon1,.img-icon2,.img-icon3 {
    width: 70%;
  }
}

@media screen and (min-width: 1501px) and (max-width: 1600px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 1.8rem;
  }
  .img-icon1,.img-icon2,.img-icon3 {
    width: 70%;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 2rem;
  }
  .img-icon1,.img-icon2,.img-icon3 {
    width: 60%;
  }
}

@media screen and (min-width: 1801px) and (max-width: 2000px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 1.9rem;
  }
  .img-icon1,.img-icon2,.img-icon3 {
    width: 40%;
  }
}

@media screen and (min-width: 2001px) and (max-width: 2200px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 2.1rem;
  }
  .img-icon1,.img-icon2,.img-icon3 {
    width: 40%;
  }
}

@media screen and (min-width: 2201px) and (max-width: 2400px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 2.3rem;
  }
  .img-icon1,.img-icon2,.img-icon3 {
    width: 40%;
  }
}

@media screen and (min-width: 2401px) and (max-width: 2600px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 2.4rem;
  }
  .img-icon1,.img-icon2,.img-icon3 {
    width: 40%;
  }
}

@media screen and (min-width: 2601px) and (max-width: 2800px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 2.6rem;
  }
  .img-icon1,.img-icon2,.img-icon3 {
    width: 40%;
  }
}

@media screen and (min-width: 2801px) and (max-width: 3000px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 2.8rem;
  }
  .img-icon1,.img-icon2,.img-icon3 {
    width: 40%;
  }
}

@media screen and (min-width: 3001px) and (max-width: 3400px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 3rem;
  }
  .img-icon1,.img-icon2,.img-icon3 {
    width: 40%;
  }
}

@media screen and (min-width: 3401px) and (max-width: 3800px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 3.3rem;
  }
  .img-icon1,.img-icon2,.img-icon3 {
    width: 40%;
  }
}

@media screen and (min-width: 3801px) and (max-width: 4200px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 3.8rem;
  }
  .img-icon1,.img-icon2,.img-icon3 {
    width: 50%;
  }
}

@media screen and (min-width: 4201px) and (max-width: 4600px) {

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 4rem;
  }
  .img-icon1,.img-icon2,.img-icon3 {
    width: 50%;
  }
}

@media screen and (min-width: 4601px){

  .txt-icon1,.txt-icon2,.txt-icon3 {
    font-size: 4.5rem;
  }
  .img-icon1,.img-icon2,.img-icon3 {
    width: 50%;
  }
}