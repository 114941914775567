@import url("https://fonts.googleapis.com/css?family=Cardo:400i|Rubik:400,700&display=swap");
:root {
  --d: 700ms;
  --e: cubic-bezier(0.19, 1, 0.22, 1);
  --font-sans: "Rubik", sans-serif;
  --font-serif: "Cardo", serif;
}

.packs{
  background-color: #d8d6d6 !important;
}

.info{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.personalized{
  padding-top: 10vh;
  padding-bottom: 1vh;
  position: relative;
  color: white;
  font-family: "Space Grotesk", sans-serif;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.p-component{
  margin-bottom: 4vh;
}

html, body {
  height: 100%;
}

body {
  display: grid;
  place-items: center;
  text-align: center;

}

.page-content {
  display: grid;
  grid-gap: 3rem;
  padding: 1rem;
  max-width: 2024px;
  margin: 0 auto;
  font-family: var(--font-sans);
  padding-bottom: 4vh;
}

.btnPacks{
  background-color: #FCD42F;
}

.p-button{
  background-color: #FCD42F !important;
}

.p-button:nth-child(1):before {
  background-color: #f5fc2f !important;
}

.two-btn{
  display: flex;
  flex-direction: row !important;
  justify-content: space-around;
}

@media (min-width: 1100px) {
  .page-content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1500px) {
  .page-content {
    grid-template-columns: repeat(3, 1fr);
  }
}

.card {
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  padding: 1rem;
  width: 100%;
  text-align: center;
  text-shadow: 4px 3px 0 #000000;
  color: whitesmoke;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);
}

@media (min-width: 600px) {
  .copy{
    font-size: 1.5rem !important;
  }
  .btn{
    padding: 1rem 1.75rem !important;
    font-size: 1rem !important;
  }

  .two-btn__a button{
    font-size: 0.5rem !important;
  }

  .btnPacks{
    padding: 1rem 1.75rem !important;
    font-size: 1rem !important;   
  }
}

@media (min-width: 1100px) {
  .copy{
    font-size: 1.8rem !important;
  }
  .btn{
    padding: 1.25rem 2rem !important;
    font-size: 1.5rem !important;
  }

  .two-btn__a button{
    font-size: 0.5rem !important;
  }

  .btnPacks{
    padding: 1.25rem 2rem !important;
    font-size: 1.5rem !important;   
  }
  .personalized{
    font-size: 3rem;
  }
}

@media (min-width: 1400px) {
  .card {
    height: 350px;
  }
  .copy{
    font-size: 1.5rem !important;
  }
}

@media (min-width: 1500px) {
  .card {
    height: 350px;
  }

}

@media (min-width: 3000px) {
  .card {
    height: 550px;
  }
  .copy{
    font-size: 1.8rem !important;
  }
  .btn{
    padding: 1.25rem 2rem !important;
    font-size: 1.5rem !important;
  }

  .two-btn__a button{
    font-size: 0.5rem !important;
  }

  .btnPacks{
    padding: 1.25rem 2rem !important;
    font-size: 1.5rem !important;   
  }
  .personalized{
    font-size: 5rem;
  }
}

@media (min-width: 3800px) {
  .card {
    height: 650px;
  }
  .copy{
    font-size: 1.8rem !important;
  }
  .btn{
    padding: 1.25rem 2rem !important;
    font-size: 1.5rem !important;
  }

  .two-btn__a button{
    font-size: 0.5rem !important;
  }

  .btnPacks{
    padding: 1.25rem 2rem !important;
    font-size: 1.5rem !important;   
  }
  .personalized{
    font-size: 5rem;
  }
}

.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-size: cover;
  background-position: 0 0;
  transition: transform calc(var(--d) * 1.5) var(--e);
  pointer-events: none;
}
.card:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  pointer-events: none;
  transform: translateY(-50%);
  transition: transform calc(var(--d) * 2) var(--e);
}
.card:nth-child(1):before {
  background-image: url('../../assets/logo/IMPACTO30.png');
  background-color: #000000;
  background-size: 100%;
  background-position:center;
  background-repeat: no-repeat;
}
.card:nth-child(2):before {
  background-image: url('../../assets/logo/IMPACTO60.png');
  background-color: #000000;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.card:nth-child(3):before {
  background-image: url('../../assets/logo/IMPACTO90.png');
  background-color: #000000;
  background-size: 100%;
  background-position:center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 375px) {
  .card:nth-child(1):before,.card:nth-child(2):before, .card:nth-child(3):before {
    background-size: 100%;
    background-position: center -15px;
  }
   
}

@media screen and (min-width: 376px) and (max-width: 415px) {
  .card:nth-child(1):before,.card:nth-child(2):before, .card:nth-child(3):before {
    background-size: 100%;
    background-position: center 8px;
  }
}

@media screen and (min-width: 416px) and (max-width: 625px) {
  .card:nth-child(1):before,.card:nth-child(2):before, .card:nth-child(3):before {
    background-size: 100%;
    background-position: center -23px;
  }
}
@media screen and (min-width: 490px) and (max-width: 780px) {
  .card:nth-child(1):before,.card:nth-child(2):before, .card:nth-child(3):before {
    background-size: 100%;
    background-position: center -60px;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  transition: transform var(--d) var(--e);
  z-index: 1;
}

.title {
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
}

.copy {
  padding-top: 5px;
  font-family: "Space Grotesk", sans-serif;
  font-size: 1.2rem;
  font-style: italic;
  line-height: 1.35;
  text-shadow: 4px 4px 4px rgb(0, 0, 0);
  margin-top: 50px;
  margin-bottom: 0;
}

.btn {
  cursor: pointer;
  padding: 1rem 1.8rem;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  color: white;
  background-color: #FCD42F;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  outline: 1px solid rgb(255, 255, 255);
  border: none;
  margin-top: 0.5rem;
}

.two-btn__a button{
  font-size: 0.8rem !important;
  margin: 0.4rem;
}

.btn:hover {
  background-color: #f8b712;
}
.btn:focus {
  outline: 1px solid yellow;
}

@media (hover: hover) and (min-width: 600px) {

.card:after {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.7%, rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.32) 58.9%, rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.54) 74.1%, rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.7%, rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.79) 100%);
}

.card:after {
  transform: translateY(0);
}

.content {
  transform: translateY(calc(100% - 4.5rem));
}
.content > *:not(.title) {
  opacity: 0;
  transform: translateY(1rem);
  transition: transform var(--d) var(--e), opacity var(--d) var(--e);
}

  .card:hover,
.card:focus-within {
    align-items: center;
  }
  .card:hover:before,
.card:focus-within:before {
    transform: translateY(-4%);
  }
  .card:hover:after,
.card:focus-within:after {
    transform: translateY(-50%);
  }
  .card:hover .content,
.card:focus-within .content {
    transform: translateY(0);
  }
  .card:hover .content > *:not(.title),
.card:focus-within .content > *:not(.title) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: calc(var(--d) / 8);
  }

  .card:focus-within:before, .card:focus-within:after,
.card:focus-within .content,
.card:focus-within .content > *:not(.title) {
    transition-duration: 0s;
  }
}