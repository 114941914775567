.home{
  display: flex;
  width: 100%;
  min-height: 100px;
}

#container-home {
  position: relative;
  width: 100%;
  height: 100%;
}