@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400);

.contact-form{
    padding-top: 5vh;
    padding-bottom: 5vh;
    background-color: #080808;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #aca49c;
  font-size: 0.875em;
}

input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder {
  color: #bbb5af;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  color: #aca49c;
  font-size: 0.875em;
}

input:focus::-moz-placeholder, textarea:focus::-moz-placeholder {
  color: #bbb5af;
}

input::placeholder, textarea::placeholder {
  color: #aca49c;
  font-size: 0.875em;
}

input:focus::placeholder, textarea::focus:placeholder {
  color: #bbb5af;
}

input::-ms-placeholder, textarea::-ms-placeholder {
  color: #aca49c;
  font-size: 0.875em;
}

input:focus::-ms-placeholder, textarea:focus::-ms-placeholder {
  color: #bbb5af;
}

input:hover::-webkit-input-placeholder, textarea:hover::-webkit-input-placeholder {
  color: #e2dedb;
  font-size: 0.875em;
}

input:hover:focus::-webkit-input-placeholder, textarea:hover:focus::-webkit-input-placeholder {
  color: #cbc6c1;
}

input:hover::-moz-placeholder, textarea:hover::-moz-placeholder {
  color: #e2dedb;
  font-size: 0.875em;
}

input:hover:focus::-moz-placeholder, textarea:hover:focus::-moz-placeholder {
  color: #cbc6c1;
}

input:hover::placeholder, textarea:hover::placeholder {
  color: #e2dedb;
  font-size: 0.875em;
}

input:hover:focus::placeholder, textarea:hover:focus::placeholder {
  color: #cbc6c1;
}

input:hover::placeholder, textarea:hover::placeholder {
  color: #e2dedb;
  font-size: 0.875em;
}

input:hover:focus::-ms-placeholder, textarea:hover::focus:-ms-placeholder {
  color: #cbc6c1;
}


#form {
  position: relative;
  width: 500px;
  margin: 50px auto 100px auto;
}

input {
  font-family: 'Lato', sans-serif;
  font-size: 0.875em;
  width: 470px;
  height: 50px;
  padding: 0px 15px 0px 15px;
  
  background: transparent;
  outline: none;
  color: #726659;
  
  border: solid 1px #b3aca7;
  border-bottom: none;
  
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

:is(#subject,#email,#submit):hover {
  background: #b3aca7;
  color: #000000;
}

#message {
  width: 470px;
  max-width: 470px;
  height: 110px;
  max-height: 110px;
  padding: 15px;
  
  background: #080808;
  outline: none;
  
  color: #726659;
  font-family: 'Lato', sans-serif;
  font-size: 0.875em;
  
  border: solid 1px #b3aca7;
  
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

#message:hover {
  background: #b3aca7 !important;
  color: #000000 !important;
}

#submit {
  width: 470px;
  
  padding: 0;
  margin: -5px 0px 0px 0px;
  
  font-family: 'Lato', sans-serif;
  font-size: 0.875em;
  color: #b3aca7;
  
  outline:none;
  cursor: pointer;
  
  border: solid 1px #b3aca7;
  border-top: none;
}

#submit:hover {
  color: #000000;
}

#header-form{
  padding-top: 12vh;
  padding-bottom: 2vh;
  position: relative;
  color: white;
  font-family: "Space Grotesk", sans-serif;
  margin: 0;
}

@media screen and (max-width: 500px) {
  #form{
    display: inline;
  }
  input{
    width: 350px;
    
  }
  textarea{
    width: 350px !important;
    max-width: 350px !important;
    
  }
  #submit{
    width: 350px;
  }

  #header-form{
    padding-bottom: 80px;
  }

  .contact-form{
    padding-bottom: 80px;
  }

}

@media screen and (max-width: 400px) {
  input{
    width: 300px;
  }
  
  textarea{
    width: 300px !important;
    max-width: 300px !important;
  }
  #submit{
    width: 300px;
  }
}

@media screen and (max-width: 350px) {
  input{
    width: 275px;
  }
  
  textarea{
    width: 275px !important;
    max-width: 275px !important;
  }
  #submit{
    width: 275px;
  }
}

@media screen and (max-width: 300px) {
  input{
    width: 250px;
  }
  
  textarea{
    width: 250px !important;
    max-width: 250px !important;
  }
  #submit{
    width: 250px;
  }
}

@media screen and (min-width: 1000px) {
  #header-form{
    font-size: 3rem !important;
  }

}

@media screen and (min-width:800px) and (max-width: 2600px) {
  
  #form{
    margin: 0;
    left: 50%;
    transform: translate(-75%);
  }
  input{
    width: 750px;
    height: 100px;
    font-size: 1.2rem;
  }
  #message{
    width: 750px !important;
    max-width: 750px !important;
    height: 100px !important;
    max-height: 100px !important;
    font-size: 1.2rem;
  }
  #submit{
    width: 750px;
    font-size: 1.2rem;
  }

  #header-form{
    padding-bottom: 80px;
  }

  .contact-form{
    padding-bottom: 80px;
    display: block !important;
    text-align: center !important;
  }
}

@media screen and (min-width:2601px) and (max-width: 3200px) {
  
  #form{
    margin: 0;
    left: 50%;
    transform: translate(-88%);
  }
  input{
    width: 900px;
    height: 130px;
    font-size: 1.5rem;
  }
  #message{
    width: 900px !important;
    max-width: 900px !important;
    height: 130px !important;
    max-height: 130px !important;
    font-size: 1.5rem;

  }
  #submit{
    width: 900px;
    font-size: 1.5rem;
  }

  #header-form{
    padding-bottom: 80px;
  }

  .contact-form{
    padding-bottom: 80px;
    display: block !important;
    text-align: center !important;
  }
}

@media screen and (min-width:3201px) {
  
  #form{
    margin: 0;
    left: 50%;
    transform: translate(-96%);
  }
  input{
    width: 1000px;
    height: 150px;
    font-size: 1.5rem;
  }
  #message{
    width: 1000px !important;
    max-width: 1000px !important;
    height: 150px !important;
    max-height: 150px !important;
    font-size: 1.5rem;

  }
  #submit{
    width: 1000px;
    font-size: 1.5rem;
  }

  #header-form{
    padding-bottom: 80px;
    font-size: 5rem !important;
  }

  .contact-form{
    padding-bottom: 80px;
    display: block !important;
    text-align: center !important;
  }
}