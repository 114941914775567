
.about{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100vw;
    text-align: center;
    font-family: "Space Grotesk", sans-serif;
    color: rgb(0, 0, 0);
    background-color: #d8d6d6 !important;
    background-size: cover;
    background-image: url(../../assets/wickedbackground.png);
}

.video-knowme {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-bottom: 10%;
  }

.knowme{
    font-size: 1.5rem;
    position: relative;
    text-align: center;
    padding: 10% 5% 10% 5%;
}

.image-container {
    position: relative;
    width: 100%;
  }
  
  .blurry-image {
    width: 400px;
    filter: blur(4px);
  }
  
  .text-overlay {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 1rem;
  }

@media screen and (min-width: 1550px) {
    .knowme {
        font-size: 2rem;
    }
  }

  @media screen and (min-width: 1320px) {
    .about {
      flex-direction: row;
      align-items: center;
    }
  
    .knowme {
      padding: 0%;
      flex: 1;
      margin-right: 10px;
    }
  
    .video-knowme {
      flex: 1;
      margin-left: 10px;
      padding: 2% 0% 2% 0%;
    }
  }