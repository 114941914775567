@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:700');
@import url("http://fonts.cdnfonts.com/css/space-grotesk");

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:15px;
	right:15px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
